<template>
  <div class="CompanyNews">
    <div class="bgimg">
      <img src="../../assets/img/CompanyNews_bgimg.png" alt />
      <div class="aboutUs_tab">
        <div :class="[tab==0?'aboutUs_tab_item2':'aboutUs_tab_item']" @click="choosebtn(0)">公司新闻</div>
        <div :class="[tab==1?'aboutUs_tab_item2':'aboutUs_tab_item']" @click="choosebtn(1)">行业新闻</div>
      </div>
    </div>

    <div class="CompanyNews_list" v-if="isDetails==false">
      <div class="Operating_Period_Projects" v-if="tab==0">
        <div class="courseDevelopment_title">
          <div class="courseDevelopment_title_style">公司新闻</div>
          <div>Company News</div>
        </div>

        <div class="CompanyNews_items">
          <div
            class="CompanyNews_item"
            v-for="(item , index) in list"
            :key="index"
            @click="LookDetail(item.id,item.url)"
          >
            <div class="CompanyNews_content">
			 <el-image :src="item.img+'?x-oss-process=image/format,jpg'" lazy></el-image>
         <!--     <img :src="item.img" alt /> -->
              <div class="CompanyNews_content_right">
                <div class="CompanyNews_content_right_title">
                  <div class="CompanyNews_content_right_title_style">{{item.title}}</div>
                  <span class="CompanyNews_content_right_title_time">{{item.issceTime}}</span>
                </div>

                <div class="CompanyNews_item_line"></div> 
                <div class="CompanyNews_item_text">{{item.summary | ellipsis(182)}}</div>

                <div class="CompanyNews_content_right_style" style="position: absolute;">
                  <span class="CompanyNews_item_lookbtn">查看更多</span>
                </div>
              </div>
            </div>
          </div>

          <el-pagination
            class="pagination_style"
            style="text-align: right;"
            background
            layout="total, sizes,prev, pager, next,jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="ruleForm.pageNum"
            :page-size="ruleForm.pageSize"
            :page-sizes="[10, 15, 20]"
            :total="total"
          ></el-pagination>
        </div>
      </div>

      <div class="Operating_Period_Projects" v-if="tab==1">
        <div class="courseDevelopment_title">
          <div class="courseDevelopment_title_style">行业新闻</div>
          <div>Industry News</div>
        </div>

        <div class="CompanyNews_items">
          <div
            class="CompanyNews_item"
            v-for="(item , index) in list"
            :key="index"
            @click="LookDetail(item.id,item.url)"
          >
            <div class="CompanyNews_content">
              <img :src="item.img" alt />
              <div class="CompanyNews_content_right">
                <div class="CompanyNews_content_right_title">
                  <div class="CompanyNews_content_right_title_style">{{item.title}}</div>
                  <span class="CompanyNews_content_right_title_time">{{item.issceTime}}</span>
                </div>

                <div class="CompanyNews_item_line"></div>
                <div class="CompanyNews_item_text">{{item.summary | ellipsis(182)}}</div>

                <div class="CompanyNews_content_right_style" style="position: absolute;">
                  <span class="CompanyNews_item_lookbtn">查看更多</span>
                </div>
              </div>
            </div>
          </div>

          <el-pagination
            class="pagination_style"
            style="text-align: right;"
            background
            layout="total, sizes,prev, pager, next,jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="ruleForm.pageNum"
            :page-size="ruleForm.pageSize"
            :page-sizes="[10, 15, 20]"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
<!-- 	<div v-html='content'></div> -->
   <company-news_details v-else :id="details_id" class="Operating_Period_Projects"></company-news_details>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import CompanyNews_details from "../components/CompanyNews_details.vue";
import { getJoList,getJoId} from "@/api/api";
export default {
  name: "CompanyNews",
  metaInfo: {
    title: "最新资讯", // set a title
    meta: [
      {
        // set meta
        name: "keyWords",
        content: "亿事达资讯,亿事达新闻,商业运营资讯"
      },
      {
        name: "description",
        content: "亿事达资讯，亿事达新闻"
      }
    ]
  },
  data() {
    return {
      ruleForm: {
        pageNum: 1,
        pageSize: 10,
        type: 0
      },
      list: [],
      tab: 0,
      total: 0,
      isDetails: false,
      details_id: "",
	  content:''
    };
  },

  filters: {
    ellipsis(value, num) {
      if (value.length > num) {
        return value.substring(0, num) + "...";
      } else {
        return value;
      }
    }
  },

  components: { CompanyNews_details },
  created() {},

  mounted() {
    this.getJoList();
    this.$store.commit("updateTab", 4);

    if (this.$route.query.subtab) {
      this.tab = this.$route.query.subtab;
      this.ruleForm.type = this.$route.query.subtab;
    }
    if (this.$route.query.isDetails) {
      this.isDetails = this.$route.query.isDetails;
      this.details_id = this.$route.query.id;
    }
  },
  methods: {
    getJoList() {
      getJoList(this.ruleForm)
        .then(res => {
          console.log(res.data);
          this.list = res.data.retData;
          this.total = res.data.total;
        })
        .catch(err => {
          console.log(err);
        });
    },

    // 判断元素距离窗口的位置
    isElemVisible(el) {
      var rect = el.getBoundingClientRect();
      var elemTop = rect.top + 200; // 200 = buffer
      var elemBottom = rect.bottom;
      return elemTop < window.innerHeight && elemBottom >= 0;
    },
    handleSizeChange(val) {
      this.ruleForm.pageNum = 1;
      this.ruleForm.pageSize = val;
      this.getJoList();
    },
    handleCurrentChange(val) {
      this.ruleForm.pageNum = val;
      this.getJoList();
    },

    choosebtn(tab) {
      this.tab = tab;
      this.ruleForm.type = tab;
      this.ruleForm.pageNum = 1;
      this.ruleForm.pageSize = 10;
      this.getJoList();
      this.isDetails = false;
    },
    LookDetail(id, url) {
		console.log(id,url)
      if (url) {
        window.open(url, "_blank");
      } else {
        this.isDetails = false;
        this.$nextTick(function() {
          this.details_id = id.toString();
          this.isDetails = true;
        });
      }
    }
  }
};
</script>
<style lang="scss">
$vm_w_base: 1920;
$vm_h_base: 1080;
@function vm_w($px) {
  @return ($px / 1920) * 100vw;
}
@keyframes fade-in {
  from {
    transform: translateY(vm_w(30));
    -webkit-box-transform: translateY(vm_w(30));
    -moz-box-transform: translateY(vm_w(30));
    -ms-box-transform: translateY(vm_w(30));
    -o-transform: translateY(vm_w(30));
    opacity: 0;
    filter: alpha(opacity = 0);
    filter: progid:DXImageTransform.Microsoft.Alpha(style = 0, opacity = 0);
  }
  to {
    transform: translateY(0);
    -webkit-box-transform: translateY(0);
    -moz-box-transform: translateY(0);
    -ms-box-transform: translateY(0);
    -o-transform: translateY(0);
    opacity: 1;
    filter: alpha(opacity = 100);
    filter: progid:DXImageTransform.Microsoft.Alpha(style = 0, opacity = 100);
  }
}
.CompanyNews {
  .bgimg {
    width: 100%;
    position: relative;
  }
  .bgimg img {
    width: 100%;
  }
  .aboutUs_tab {
    width: vm_w(1200);
    height: vm_w(64);
    background-color: #ffffff;
    position: absolute;
    bottom: vm_w(-32);
    z-index: 10;
    left: calc(50% - #{vm_w(600)});
    left: -webkit-calc(50% - #{vm_w(600)});
    left: -moz-calc(50% - #{vm_w(600)});
    left: -ms-calc(50% - #{vm_w(600)});
    left: -o-calc(50% - #{vm_w(600)});
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.08);
  }
  .aboutUs_tab_item {
    width: 50%;
    height: 100%;
    line-height: vm_w(64);
    display: inline-block;
    vertical-align: top;
    text-align: center;
    cursor: pointer;
    font-size: vm_w(16);
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    color: #666666;
  }
  .aboutUs_tab_item2 {
    width: 50%;
    height: 100%;
    line-height: vm_w(64);
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    font-size: vm_w(16);
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    color: $chooseColor;
  }
  .Operating_Period_Projects {
    width: vm_w(1200);
    padding-bottom: vm_w(64);
    margin: 0 auto;
  }
  .courseDevelopment_title {
    width: 100%;
    text-align: center;
    font-size: vm_w(32);
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    opacity: 0;
    filter: alpha(opacity = 0);
    filter: progid:DXImageTransform.Microsoft.Alpha(style = 0, opacity = 0);
    animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
    -webkit-box-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
    -moz-box-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
    -ms-box-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
    -o-animation: fade-in 0.6s cubic-bezier(0.32, 0.94, 0.6, 1);
    animation-fill-mode: forwards;
    -webkit-box-animation-fill-mode: forwards;
    -moz-box-animation-fill-mode: forwards;
    -ms-box-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
  }
  .CompanyNews_items {
    margin-top: vm_w(32);
  }
  .CompanyNews_item {
    width: 100%;
    height: vm_w(236);
    border-bottom: solid vm_w(1) #f5f5f5;
  }
  .CompanyNews_item:hover {
    background-color: #f5f5f5;
  }
  .CompanyNews_item:hover .CompanyNews_content_right_title {
    color: $chooseColor;
  }
  .CompanyNews_item:hover .CompanyNews_item_text {
    color: $chooseColor;
  }
  .CompanyNews_item:hover .CompanyNews_item_line {
    background-color: $chooseColor;
  }
  .CompanyNews_item:hover .CompanyNews_item_lookbtn {
    color:$chooseColor;
    text-decoration: underline;
  }
  .CompanyNews_item:hover .CompanyNews_content_right_title_style {
    color: $chooseColor;
  }
  .CompanyNews_item:hover .CompanyNews_item_text:after {
    background-color: #f5f5f5;
  }

  .CompanyNews_content {
    width: vm_w(1136);
    height: vm_w(172);
    padding: vm_w(32);
    cursor: pointer;
  }

  .CompanyNews_content img {
    width: vm_w(242);
    height: vm_w(172);
    display: inline-block;
    vertical-align: top;
  }
  .CompanyNews_content_right {
    width: vm_w(858);
    height: 100%;
    display: inline-block;
    vertical-align: top;
    margin-left: vm_w(32);
    position: relative;
  }
  .CompanyNews_content_right_title {
    font-size: vm_w(18);
    font-family: Source Han Sans CN, Source Han Sans CN-Medium;
    font-weight: 500;
    color: #666666;
    margin-top: vm_w(5);
  }
  .CompanyNews_content_right_title_style {
    width: vm_w(658);
    color: #333333;
    font-weight: bold;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .CompanyNews_content_right_title_time {
    float: right;
    font-weight: 400;
    display: inline;
    font-size: vm_w(14);
    line-height: vm_w(18);
  }
  .pagination_style {
    margin-top: vm_w(32);
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: $chooseColor !important;
  }

  .CompanyNews_content_right_style {
    bottom: vm_w(5);
  }
  .CompanyNews_item_line {
    width: vm_w(172);
    height: vm_w(4);
    background: #999999;
    margin-top: vm_w(16);
  }
  .CompanyNews_item_text {
    font-size: vm_w(14);
    font-family: Source Han Sans CN, Source Han Sans CN-Normal;
    color: #666666;
    line-height: vm_w(24);
    margin-top: vm_w(12);
    position: relative;
    overflow: hidden;
    word-break: break-all;
    height: vm_w(70);
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    // max-height: vm_w(72); /*这里是是行高的几倍就表示显示几行文本*/
  }

  // .CompanyNews_item_text:before {
  // }

  // .CompanyNews_item_text > :first-child {
  //   float: right;
  //   width: 100%;
  //   margin-left: vm_w(
  //     -5
  //   ); /*让main元素左移5px，这样main元素在宽度上就完全占满了父元素；*/
  //   word-break: break-all;
  //   box-sizing: border-box;
  //   text-align: justify;
  // }
  // .CompanyNews_item_text:after {
  //   content: "...";
  //   box-sizing: content-box;
  //   float: right;
  //   position: absolute;
  //   top:vm_w(42);

  //   right: 0;
  //   /*设置margin-left。padding-right。则是为了让realend元素的盒模型的最终宽度计算为5px。*/
  //   margin-left: vm_w(-11);
  //   padding-right: vm_w(5);
  //   text-align: left;
  //   background: #fff; /* W3C */
  // }

  .CompanyNews_item_lookbtn {
    font-size: vm_w(14);
    font-family: Source Han Sans CN, Source Han Sans CN-Normal;
    color: #333333;
  }
  .courseDevelopment_title_style {
    margin-top: vm_w(93);
    margin-bottom: vm_w(10);
  }
}
</style>